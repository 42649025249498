import React from 'react';

import LogoImage from '../../assets/lufthansa_logo.svg';
import './styles.scss';

const Logo = () => (
  <a className="logo-wrapper" href="https://www.lufthansa.com/">
    <img className="logo" src={LogoImage} alt="Lufthansa logo" />
  </a>
);

export default Logo;
